:root {
  --frontend-fontsize: 1.2rem;
  --editor-fontsize: 12px;
  --heading-offset: 19.5px;
}
@media (min-width: 360px) {
  :root {
    --frontend-fontsize: 1.4rem;
    --editor-fontsize: 14px;
    --heading-offset: 22.75px;
  }
}
@media (min-width: 782px) {
  :root {
    --frontend-fontsize: 1.6rem;
    --editor-fontsize: 16px;
    --heading-offset: 26px;
  }
}
@media (min-width: 1200px) {
  :root {
    --frontend-fontsize: 1.8rem;
    --editor-fontsize: 18px;
    --heading-offset: 29.25px;
  }
}

/*@import "~bootstrap/scss/bootstrap";*/
.card-heading {
  background-color: #48515d;
  color: #fff;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
}

.card {
  border: 0px solid white;
}

.popover {
  font-size: 20px;
}

.testabc {
  height: "101px";
}

body.modal-open .datepicker {
  z-index: 1200 !important;
}

.navbar-inverse {
  background-color: #48515d;
}

.navbar-inverse .navbar-nav > li > a {
  color: #fff;
  font-weight: bold;
}

html {
  position: relative;
  min-height: 100%;
  font-size: 10px !important;
}

body {
  color: rgb(103, 110, 120);
  margin-bottom: 60px;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  line-height: 1.625;
}

@supports (--css: variables) {
  body {
    font-size: var(--frontend-fontsize);
  }
}

h3 {
  font-size: 1.5em;
}

body > .container {
  padding: 60px 15px 0;
}

.btn-primary {
  color: #323232 !important;
  background-color: #ffffff !important;
  border-color: #587db3 !important;
}

.btn-primary:hover {
  background-color: #587db3 !important;
  color: #ffffff !important;
}

.btn-mts {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(76, 129, 186) !important;
  font-size: 1em;
  border: 0px solid rgba(0, 0, 0, 0) !important;
  /*transition: all 0.8*/
  border-radius: 0px;
  opacity: 1 !important;
  font-weight: 400;
  line-height: 1.5em;
  padding-top: 0.5em;
  padding-left: 0.75em;
  padding-bottom: 0.5em;
  padding-right: 0.75em;
}

.btn-mts:hover {
  background-color: #587db3 !important;
  color: #ffffff !important;
}

.has-error,
.has-error .has-feedback,
.has-error .control-label,
.has-error .help-block,
.has-error .checkbox-inline {
  color: #767676;
}

.has-error .input-group-addon {
  color: #767676;
  background-color: #767676;
}

.form-control {
  border-radius: 0px;
}

/*
.has-error .form-control {
	border-color: #767676;
}*/

.has-error .form-control-feedback {
  color: #a94442;
}

.panel {
  border-radius: 0px;
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.panel-heading {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.panel-default > .panel-heading {
  background-color: #48515d;
}

.panel-default > .panel-heading {
  color: #fff;
}

.panel-footer {
  border-top: none;
  background-color: #ffffff;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.well {
  background-color: #48515d;
}

.card-footer {
  background-color: transparent;
  border-top: 0px solid transparent;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

.mts-input {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5em;
}

.popover-body {
  font-size: 15.75px;
  color: #676e78;
}

.icon-header {
  /*width: 100%;*/
}

.header1 {
  border-top: 0px solid white;
  border-bottom: 0px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background-color: white;
  z-index: 10;
}

.zero-links {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.header-btn-row {
  display: flex;
  flex-direction: column;
}

.center {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

/* sm */
@media (min-width: 576px) {
  .header1 {
    border-top: 0px solid white;
    border-bottom: 0px solid white;
    flex-direction: column;
  }
  .header-btn-row {
    display: flex;
    flex-direction: column;
  }
  .center {
    margin-bottom: 5px;
  }
  .zero-links {
    flex-direction: column;
  }
}

/* md */
@media (min-width: 768px) {
  .header1 {
    border-top: 0px solid white;
    border-bottom: 0px solid white;
    gap: 0px;
    flex-direction: column;
  }
  .header-btn-row {
    display: flex;
    flex-direction: column;
  }
  .center {
    margin-bottom: 5px;
  }
  .zero-links {
    flex-direction: column;
  }
}

/* lg */
@media (min-width: 910px) {
  .header1 {
    flex-direction: row;
    width: 1000px;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
  }
  .header-btn-row {
    display: flex;
    flex-direction: row;
  }
  .center {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .zero-links {
    flex-direction: row;
  }
}

/* xl */
@media (min-width: 1200px) {
  .header1 {
    flex-direction: row;
    width: 1100px;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .header-btn-row {
    display: flex;
    flex-direction: row;
  }
  .center {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .zero-links {
    flex-direction: row;
  }
}

/* xxl */
@media (min-width: 1400px) {
  .header1 {
    flex-direction: row;
    width: 1300px;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
  }
  .header-btn-row {
    display: flex;
    flex-direction: row;
  }
  .center {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .zero-links {
    flex-direction: row;
  }
}

@media (min-width: 1600px) {
  .header1 {
    flex-direction: row;
    width: 1550px;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
  }

  .zero-links {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .icon-header {
    /*width: 750px;*/
  }

  .mts-column {
    width: 100%;
  }

  .mts-input {
    width: 100%;
  }

  .card-footer {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .icon-header {
    /*width: 970px;*/
  }

  .mts-input {
    width: 100%;
  }

  .mts-column {
    width: 100%;
  }

  .card-footer {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .icon-header {
    /*width: 1320px;*/
  }

  .mts-input {
    width: 100%;
  }

  .mts-column {
    width: 100%;
  }

  .card-footer {
    width: 100%;
  }
}

@media (max-width: 1010px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,
  .navbar-right {
    float: none !important;
  }
  .navbar-right {
    margin-right: 0;
    /*background-color: #2c3e50;*/
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > ul {
    width: 100%;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .collapse.in {
    display: block !important;
  }
}

.input-sm {
  line-height: 2.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  vertical-align: middle;
  background-color: white;
  color: #868c93;
  border: 1px solid;
  border-color: #e3e4e5;
  border-radius: 0;
  transition: background-color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), border-color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955), color 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #676e78;
  font-size: 1em;
  line-height: 1.5em;
  margin: 0;
}

.input-sm:focus {
  color: #676e78;
  background-color: white;
  border-color: #6794c4;
}

.input-mts {
  line-height: 1.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  vertical-align: middle;
  background-color: white;
  color: #868c93;
  border: 1px solid;
  border-color: #e3e4e5;
  border-radius: 0;
  transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), border-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #676e78;
  font-size: 0.875em;
  line-height: 1.5em;
  margin: 0;
  width: 100%;
  font-weight: 400;
}

.input-mts-select {
  border-radius: 0px;
  padding-top: 0.64em;
  padding-bottom: 0.64em;
}

@supports (-webkit-touch-callout: none) {
  .input-mts-select {
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    border-radius: 0px;
  }
}

.btn {
  font-size: 0.875em;
}

.btn-close {
  width: 0.125em;
  height: 0.125em;
}

.checkbox-inline {
  /*line-height: 1.5em;
  color: #868c93;
  font-size: 1em;
  line-height: 1.5em;
  font-weight: 400;*/

  display: inline-block;
  margin-bottom: 0.5em !important;
  padding-left: 1px;
  font-family: "Roboto", sans-serif;
  font-size: 0.875em;
  font-weight: 400;
  color: #676e78;
  /*color: black;*/
}

.input-mts-label {
  display: inline-block;
  margin-bottom: 0.5em !important;
  padding-left: 1px;
  font-family: "Roboto", sans-serif;
  font-size: 0.875em;
  font-weight: 400;
  color: #676e78;
}

.input-mts:focus {
  color: #676e78;
  background-color: white;
  border-color: #6794c4;
  outline: 0;
}

.errorStar-showing {
  color: #c92a38;
  font-weight: 400;
  text-decoration: none;
  visibility: visible;
  transition: visibility 1s, opacity 0.5s linear;
  opacity: 1;
}

.errorStar-not-showing {
  color: #c92a38;
  font-weight: 400;
  text-decoration: none;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 0.5s linear;
}

.hide {
  display: none;
}

.modal-content {
  border-radius: 0px;
}

.alert {
  border-radius: 0px;
}

.alert-info {
  color: #c92a38;
  background-color: white;
  border-color: white;
}

.modal-footer {
  border-top: 0px;
}

.modal-header {
  border-bottom: 0px;
}

.btn-mts-upload {
  margin: 0px 0px 0px 0px;
  color: #fff !important;
  background-color: #c5b777 !important;
  border: 1px solid transparent !important;
  border-radius: 3.112px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.25em;
  transition: background-color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955), border-color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955), color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  text-align: center;
  text-decoration: none;
  white-space: normal;
  word-break: break-word;
  vertical-align: middle;
  box-sizing: border-box;
}

/*
.btn-mts-upload:hover {
  background-color: #587db3 !important;
  color: #ffffff !important;
}*/

.btn-mts-big {
  margin: 0px 5px 0px 5px;
  color: #323232 !important;
  background-color: #ffffff !important;
  border: 2px solid #587db3 !important;
  font-weight: bold;
  font-size: 20px;
  transition: all 0.8s;
  border-radius: 5px;
}

.btn-mts-big:hover {
  background-color: #587db3 !important;
  color: #ffffff !important;
}

.circle:before {
  content: " \25CF";
  font-size: 25px;
  color: #587db3;
  z-index: 100;
}

.circle-gray:before {
  content: " \25CF";
  font-size: 25px;
  color: #dcdcdc;
  z-index: 100;
}

.mts-block {
  font-size: 0.875em;
  hyphens: auto;
  text-align: justify;
}

.mts-error {
  color: #c92a38;
  font-weight: 400;
  font-size: 0.875em;
  margin-bottom: 1px;
  margin-top: 1px;
}

.button-mts {
  border-radius: 0;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  max-width: 100%;
  font-size: 0.875em;
  line-height: 1.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  vertical-align: middle;
  margin: 0;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  white-space: normal;
  word-break: break-word;
  border: 1px solid;
  border-color: transparent;
  border-radius: 0;
  transition: background-color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955), border-color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955), color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  cursor: pointer;
  overflow: hidden;
  color: white;
  background-color: #4c81ba;
}

.button {
  border-radius: 0;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  max-width: 100%;
  font-size: 0.875em;
  line-height: 1.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.75em;
  padding-right: 0.75em;
  vertical-align: middle;
}

.button.disabled,
.button:disabled {
  opacity: 0.65;
  cursor: not-allowed !important;
}

.button:not(.alt) {
  margin: 0;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  white-space: normal;
  word-break: break-word;
  border: 1px solid;
  border-color: transparent;
  border-radius: 0;
  transition: background-color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955), border-color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955), color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  cursor: pointer;
  overflow: hidden;
  color: white;
  background-color: #4c81ba;
}

.button:not(.alt):visited {
  color: white;
}
.button:not(.alt):hover,
.button:not(.alt):focus {
  color: white;
  background-color: #5e8ec1;
}
.button:not(.alt):active {
  color: white;
  background-color: #4274ab;
}
.button.alt {
  margin: 0;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  white-space: normal;
  word-break: break-word;
  border: 1px solid;
  border-color: transparent;
  border-radius: 0;
  transition: background-color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955), border-color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955), color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  cursor: pointer;
  overflow: hidden;
  color: white;
  background-color: #c5b777;
  border-top-color: rgba(226, 219, 187, 0.25);
  border-left-color: rgba(226, 219, 187, 0.25);
  border-right-color: rgba(226, 219, 187, 0.125);
}
.button.alt:visited {
  color: white;
}
.button.alt:hover,
.button.alt:focus {
  color: white;
  background-color: #cdc089;
}
.button.alt:active {
  color: white;
  background-color: #bdae65;
  border-color: rgba(0, 0, 0, 0);
}
.button .inline-svg.inline {
  top: 0.0625em;
  min-width: 0;
  margin-right: 0.25em;
  height: 1em;
}

.button-tiny {
  font-size: 0.75em;
}

.button-small {
  font-size: 0.875em;
}

.button-medium {
  font-size: 1.125em;
}

.button-large {
  font-size: 1.375em;
}

.space-line {
  min-height: 0.5em;
}

.button-huge {
  font-size: 1.75em;
}

.card-body {
  padding-bottom: 5px;
}

.card-heading {
  font-size: 0.875em;
}

.flash-fx:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
  transition: none;
}

.flash-fx:hover:before {
  left: 100%;
  transition: left 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.site-topbar {
  font-size: 0.89em;
  /*padding-top: 0.5em;*/
  /*padding-bottom: 0.5em;*/
  /*background-color: #48515d;*/
}

.mts-strong {
  font-size: 1em;
  font-weight: 600;
}

.mts-heading-btn{
  padding-top: 5px;
  padding-bottom: 5px;
}

.mts-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #363d45;
}

.mts-footer-link {
  text-decoration: none;
  color: #868c93;
  font-weight: 300;
  font-size: 0.83em;
}

.mts-footer-link:hover { 
  color: #c4c7ca;
  text-decoration: none; 
}

.mts-footer-seperator {
  text-decoration: none;
  color: #868c93;
  font-weight: 300;
  font-size: 0.83em;
}

span.wpcf7-not-valid-tip {
  display: block;
  position: relative;
  top: -2em;
  left: calc(0.75em + 1px);
  right: calc(0.75em + 1px);
  font-size: 1em;
  line-height: 1.5em;
  color: #c92a38;
  background-color: white;
  transition: opacity 0.2s cubic-bezier(0.755, 0.05, 0.855, 0.06), transform 0.2s cubic-bezier(0.755, 0.05, 0.855, 0.06);
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: text;
  overflow: hidden;
  z-index: 1;
  margin-right: 15px;
}

span.wpcf7-not-valid-tip.clicked {
  opacity: 0;
  transform: translate3d(30px, 0, 0);
  pointer-events: none;
}

span.wpcf6-not-valid-tip {
  display: block;
  font-size: 1em;
  line-height: 1.5em;
  color: #c92a38;
  background-color: white;
  transition: opacity 0.2s cubic-bezier(0.755, 0.05, 0.855, 0.06), transform 0.2s cubic-bezier(0.755, 0.05, 0.855, 0.06);
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: text;
  overflow: hidden;
  z-index: 1;
}

span.wpcf6-not-valid-tip.clicked {
  opacity: 0;
  transform: translate3d(30px, 0, 0);
  pointer-events: none;
}

.mts-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-basis: 100%;
}

.mts-column-left {
  width: 100%;
}

.mts-column-right2 {
  width: 100%;
}

.mts-column-right {
  width: 50%;
  display: none;
}

@media (min-width: 992px) {
  .mts-column-left {
    width: 50%;
  }

  .mts-column-right {
    width: 50%;
    display: block;
  }

  .mts-column-right2 {
    display: none;
  }

  .mts-svg-icon {
    margin-top: auto;
    margin-bottom: auto;
    height: 100%;
  }
  .mts-svg-icon.right {
    margin-left: 0.5em;
  }

  .mts-svg-icon.left {
    margin-right: 0.5em;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 1200px){
.container {
    max-width: 720px;
  }
}

@media (min-width: 992px){
.container {
    max-width: 720px;
}
}

@media (min-width: 576px){
.container {
  max-width: 720px;
}
}